.transactDiv{
    border: 1px solid #DFE0EB;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 50px 30px;
}

.transactStatus{
    display: flex;
    align-items: center;
}

.transactImage{
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    height: 120px;
}

.transactImageSize{
    width: 100%;
    height: 120px;
}

.btn-transact{
    width: 50%;
}


/* Media query for smaller phones */

@media (max-width:767px) {
    .btn-transact{
        width: 100%;
    }

}