.body-hidden {
    overflow: hidden;
}

/* navbar styles */
header{
    padding: 5px 0px;
    transition: all .3s ease-in-out;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    background: #fff;
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
}

.header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainNav{
    margin: 0;
    padding: 0;
    text-align: center;
    display: inline-block;
    list-style: none;
}

.mainNav li{
    display: inline-block;
}

.mainNav li a {
    color: #2C3A50;
    margin: 0 22px;
    line-height: 19px;
    position: relative;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
}

.mainNav li a.active, .mainNav li a:hover {
    color: #0898D7;
}

.mainNav li.navLink a:before {
    position: absolute;
    bottom: -8px;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 3px;
    content: '';
    transition: all .3s ease-in-out 0s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    background-color: #0898D7;
}

.mainNav li a.active:before, .mainNav li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.mainNav li.headerbtn a {
    line-height: 20px;
    color: #fff;
    margin-right: 5px;
    /* margin-left: 10px; */
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 112px;
    text-transform: uppercase;
}

.navicon{
    position: fixed;
    z-index: 99999;
    height: 26px;
    right: 30px;
    top: 18px;
    display: none;
    transition: all .3s ease-in-out;
    width: 35px;
}

.nav-toggle{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.nav-toggle span{
    content: "";
    position: absolute;
    display: block;
    width: 35px;
    height: 2px;
    border-radius: 1px;
    background: #0898D7;
    cursor: pointer;
    top: 10px;
    transition: all .2s ease-in-out;
}

/* active hamburger for close */
.nav-toggle.active span {
    background-color: transparent;
}

.nav-toggle.active span:before {
    top: 0;
}
.nav-toggle.active span:before {
 -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav-toggle.active span:after {
     top: 10px;
    -webkit-transform: translatey(-10px) rotate(-45deg);
    transform: translatey(-10px) rotate(-45deg);
}

.nav-toggle span:before{
    content: "";
    position: absolute;
    display: block;
    width: 35px;
    height: 2px;
    border-radius: 1px;
    background: #0898D7;
    cursor: pointer;
    top: 10px;
    transition: all .2s ease-in-out
}
.nav-toggle span:before {
    top: -10px;
}
.nav-toggle span:after {
    bottom: -10px;
}
.nav-toggle span:after{
    content: "";
    position: absolute;
    display: block;
    width: 35px;
    height: 2px;
    border-radius: 1px;
    background: #0898D7;
    cursor: pointer;
    top: 10px;
    transition: all .2s ease-in-out;
}

/* hero styles */
.hero{
    background: #fff;
    /* background-size: cover; */
    display: flex;
    /* align-items: center; */
    padding: 150px 0px 0px 0px;
    position: relative;
}

.hero-circle{
    display: block;
    position: absolute;
    bottom: 40px;
    right: 550px;
}

.hero-scratch{
    display: block;
    position: absolute;
    right: 150px;
    top: 100px;
}

.hero-title{
    color: #373737;
    font-weight: 700;
    font-family: 'Poppins';
    max-width: 20rem;
    line-height: 3rem;
}

.hero-subtitle{
    color: #5B5B5B;
    font-weight: 500;
    max-width: 26rem;
}

.hero_cta{
    display: flex;
}

.btn_cta{
    background: #9d0957;
    border-radius: 10px;
    color: white;
    padding: 12px 40px;
    transition: all ease-in-out .15s;
}

.btn_cta:hover{
    background-color: #AB0351;
    color: white;
}

.btn_ctaStart{
    background: #0898D7;
    border-radius: 10px;
    color: white;
    padding: 12px 40px;
    margin-right: 10px;
    font-weight: 500;
    transition: all ease-in-out .15s;
}

.btn_ctaStart:hover{
    background: #0d92d5;
    color: white;
  }


/* rate calculator section */
.rate{
    background: #F2F5F7;
    padding: 80px 150px;
}

.rate-text{
    color: #2C3A50;
    font-weight: 700;
}

.input-style{
    border: 1px solid rgba(8, 152, 215, 0.2);
    border-radius: 5px;
    padding: 25px 20px;
    color: #2C3A50;
  }
  .input-style:focus {
    border: 1px solid rgba(8, 152, 215, 0.2);
    box-shadow: none;
    outline: 0 none;
  }

  .input-container{
      position: relative;
  }

  .amount-style{
    position: absolute;
    /* top: 0px; */
    right: 0px;
    /* padding: 12px; */
    text-align: center;
    background: #fff;
    border: 1px solid rgba(8, 152, 215, 0.2);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .amount-div{
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .amount-disabled:disabled{
    opacity: 1;
    background: #fff;
  }


  .select-style{
    border: 1px solid rgba(8, 152, 215, 0.2);
    height: 52px;  
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(../../assets/images/selectArrow.svg);
    background-position:
      calc(100% - 20px) calc(1em + 4px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .select-style:focus{
    border: 1px solid rgba(8, 152, 215, 0.2);
    outline: 0 none;
    box-shadow: none; 
  }

  .calc-size{
      width: auto;
  }

  /* features */
  .features{
      background: #fff;
      padding: 80px 60px;
  }
  .features-list{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  /* bills */
  .topUpDiv{
    background: #F2F5F7;
    padding: 80px 60px;
   
  }

  .topUpHeading{
    color: #2C3A50;
    font-weight: 700;
  }

  .topUpSubtitle{
    color: #2C3A50;
    font-weight: 400;
    max-width: 22rem;
  }

  /* steps */
  .steps{
      padding: 80px 100px;
      background: #F2F5F7;
  }
  .steps-head{
    color: #2C3A50;
    font-weight: bold;
    line-height: 45px;
  }
  .steps-p{
      color: #2C3A50;
  }

  /* testimonials */
.testimonials{
    padding: 80px 100px;
    background: #fff;
}
.testimonials-div{
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 30px 20px;
    width: 90% !important;
    height: 300px;
}

/* Coming soon section */
.comingBg{
    padding: 80px 0px;
    background: #F2F5F7;
}

.comingDiv{
    display: flex;
    align-items: center;
    max-width: 48rem;
    margin: auto;
    flex-direction: row;
}

.comingTitle{
    color: #2C3A50;
    font-weight: 800;
}

.comingSubtitle{
    color: #2C3A50;
    font-weight: 400;
    max-width: 30rem;
}

.comingText{
    flex: 1;
    margin-right: 50px;
}

.comingImg{
    flex: 1;
}

.comingImg img{
    height: 30rem;
}

/* cards */
.cards{
    padding: 80px 100px;
    background: #fff;
}

/* footer */
.footer{
    background: #2C3A50;
    padding-top: 80px;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
    color: white;
}
.footer-links{
    list-style: none;
    padding: 0px;
}
.footer-links li a{
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    transition: all ease-in-out .15s;
}
.footer-links li a:hover{
    color: #0898D7
}

/* modal dialog styles */
.modal-content{
    background: #fff;
    position: initial;
    border-radius: 30px;
    border: 1px solid #fff; 
    /* /* height: 515px; */
}

.modal-content.trade{
    background: #2C3A50;
    position: initial;
    border-radius: 30px;
    border: 1px solid #2C3A50;
}


.login-text{
    color: white;
    font-weight: 800;
}
.modal-contain{
    padding: 80px 40px 30px 40px;
}

.modal-success{
    padding: 80px 40px;
}

.modal-header {
    padding: 0px;
    border-bottom: 0px;
}

.contain-head{
    padding: 0px 40px
}

/* input styles */
.input-style{
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 25px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #2C3A50;
  }
  .input-style:focus {
    border: 1px solid #D1D1D1;
    box-shadow: none;
    outline: 0 none;
    color: #2C3A50;
  }

  .registerImage{
      position: absolute;
      top: 0px;
      left: 70px;
  }
  

  /* mobile login div */
  .login-div{
    padding: 10px 60px;
  }



/* Media query for smaller phones */

@media (max-width:767px) {
    /* navbar section */
    .navicon{
        display: block;
    }
    header nav{
        position: fixed;
        background: rgba(0,0,0,0);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 99999;
        visibility: hidden;
    }
    header nav.open {
        visibility: visible;
        background: rgba(0,0,0,.9);
    }
    .mainNav {
        position: relative;
        left: 50%;
        top: 50%;
        /* -webkit-transform: translate(-50%,-50%); */
        transform: translate(-50%,-50%);
    }

    .mainNav li:nth-child(1) {
        transition-delay: 50ms;
    }

    header nav .mainNav li {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: translate3d(0,-80px,0);
        transform: translate3d(0,-80px,0);
        transition: opacity .5s,-webkit-transform .5s;
        transition: transform .5s,opacity .5s;
        transition: transform .5s,opacity .5s,-webkit-transform .5s;
        display: block;
    }

    header nav.open .mainNav li {
        opacity: 1;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        margin-bottom: 20px;
    }

    .mainNav li a {
        color: #fff;
        font-size: 15px;
    }
    
    .mainNav li.navLink a:before {
        background: none;
    }

    .mainNav li a.active, .mainNav li a:hover {
        color: #0898D7;
    }

    /* hero */
    .hero{
        padding: 120px 0px 0px 0px;
    }
    .hero-title{
        
    }

    .hero-circle{
        display: none;
    }

    .hero-scratch{
        display: none;
    }

       /* rate section */
    .rate{
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .calc-size{
        width: 100%;
    }
    
    /* features */
    .features{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    /* bills */
    .topUpDiv{
        padding-left: 15px;
        padding-right: 15px;
    }

    /* steps */
    .steps{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .steps-head{
        line-height: initial;
    }

    /* testimonials */
    .testimonials{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .testimonials-div{
        width: 95% !important;
        height: auto;
    }

    /* coming soon div */
    .comingBg{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .comingDiv{  
        flex-direction: column;
    }

    .comingText{
        margin-right: 0px;
        text-align: center;
    }
    

    /* cards */
    .cards{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    /* footer */
    .footer{
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

    /* login text */
    .login-text{
        color: #0898D7;
        font-weight: 800;
    }
    .modal-contain{
        padding: 20px 40px;
    }
    .modal-success{
        padding: 30px 40px;
    }
    .modal-header{
        padding: 1rem 1em;
    }
    .registerDialog .modal-dialog{
        width: auto;
    }

    .login-div{
        padding: 10px 10px;
      }

}